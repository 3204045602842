// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import ProductCard from './ProductCard2'

import PIC_PRODUCT1 from 'assets/images/product1-a.png'
import PIC_PRODUCT2 from 'assets/images/product2-b.png'
import PIC_PRODUCT3 from 'assets/images/product3-a.png'
import PIC_PRODUCT4 from 'assets/images/product4-a.png'
import PIC_PRODUCT5 from 'assets/images/product5-a.png'

import CONTENT from 'content/content'

export default (props) => {
    return (
        <section className="inner">
            <div className="row">
                <div className="12u row">
                    <div className="4u">
                        <ProductCard src={PIC_PRODUCT1} action={CONTENT.actions.learnMore} href="/product1" title={CONTENT.products.group1.title} description={CONTENT.products.group1.sub} />
                    </div>
                    <div className="4u">
                        <ProductCard src={PIC_PRODUCT2} action={CONTENT.actions.learnMore} href="/product2" title={CONTENT.products.group2.title} description={CONTENT.products.group2.sub} />
                    </div>
                    <div className="4u">
                        <ProductCard src={PIC_PRODUCT3} action={CONTENT.actions.learnMore} href="/product3" title={CONTENT.products.group3.title} description={CONTENT.products.group3.sub} />
                    </div>
                </div>
                <div className="12u row">
                    <div className="4u">
                        <ProductCard src={PIC_PRODUCT4} action={CONTENT.actions.learnMore} href="/product4" title={CONTENT.products.group4.title} description={CONTENT.products.group4.sub} />
                    </div>
                    <div className="4u">
                        <ProductCard src={PIC_PRODUCT5} action={CONTENT.actions.learnMore} href="/product5" title={CONTENT.products.group5.title} description={CONTENT.products.group5.sub} />
                    </div>
                </div>
            </div>
        </section>
    )
}
