// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
// import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player'

// https://www.npmjs.com/package/react-player#responsive-player
// https://www.npmjs.com/package/react-player#single-player-imports

export default (props) => {
    const { src } = props
    return (
        <div className="video shadow react-player-wrapper">
            <ReactPlayer
                className='react-player'
                url={src}
                playing={false}
                loop={true}
                muted={false}
                controls={true}
                width='100%'
                height='100%'
                config={{
                    youtube: {
                        playerVars: { modestbranding: 1, rel: 0 }
                    },
                }}
            />
        </div>
    )
}
