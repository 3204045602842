// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-jsx": () => import("./../../../src/pages/landing.jsx" /* webpackChunkName: "component---src-pages-landing-jsx" */),
  "component---src-pages-product-1-jsx": () => import("./../../../src/pages/product1.jsx" /* webpackChunkName: "component---src-pages-product-1-jsx" */),
  "component---src-pages-product-2-jsx": () => import("./../../../src/pages/product2.jsx" /* webpackChunkName: "component---src-pages-product-2-jsx" */),
  "component---src-pages-product-3-jsx": () => import("./../../../src/pages/product3.jsx" /* webpackChunkName: "component---src-pages-product-3-jsx" */),
  "component---src-pages-product-4-jsx": () => import("./../../../src/pages/product4.jsx" /* webpackChunkName: "component---src-pages-product-4-jsx" */),
  "component---src-pages-product-5-jsx": () => import("./../../../src/pages/product5.jsx" /* webpackChunkName: "component---src-pages-product-5-jsx" */)
}

