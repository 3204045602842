// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { children } = props
    return (
        <h4>
            {React.Children.map(children, (value) => value)}
        </h4>
    )
}
