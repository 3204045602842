// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import IconButton from './IconButton'

// content
import CONTENT from 'content/content'

export default (props) => {
        const { to, subject, body, text } = props
        const TO = to || CONTENT.email.address
        const SUBJECT = subject || CONTENT.email.subject
        const BODY = body || CONTENT.email.body
        const TEXT = text || CONTENT.actions.emailUs
        const FULL_BODY = BODY
        const HREF = encodeURI(`mailto:${TO}?subject=${SUBJECT}&body=${FULL_BODY}`)
        return (
            <IconButton href={HREF} text={TEXT} icon="fa-envelope" />
        )
}
