// import "@babel/polyfill"

import React from "react"

import MainLayout from './src/components/MainLayout'

// https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
// https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
export const wrapPageElement = ({ element, props }) => {
    return (
        <MainLayout>{element}</MainLayout>
    )
}
