import React from 'react'
import { Link } from 'gatsby'
import { StickyContainer, Sticky } from 'react-sticky'

// content
import CONTENT from 'content/content'

// https://github.com/captivationsoftware/react-sticky#full-example

export default (props) => {
    const { onToggleMenu } = props
    return (
        <StickyContainer>
            <Sticky>
            {({style}) => (
                <header style={style} id="header">
                    <Link to="/" className="logo"><strong>{CONTENT.header.logo}</strong> <span>{CONTENT.header.name}</span></Link>
                    <nav>
                        <a
                            className="menu-link"
                            onClick={(e) => {
                                e.preventDefault()
                                onToggleMenu()
                            }} 
                            href="#contact_us">{CONTENT.header.menu}</a>
                    </nav>
                </header>
            )}
            </Sticky>
        </StickyContainer>
    )
}
