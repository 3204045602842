// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import IconButton from './IconButton'

class MapButton extends React.Component {

    render() {

        // http://www.5dt.com/contact/
        // https://www.google.com/maps?ll=28.581617,-81.197251&z=16&t=m&hl=en-US&gl=ZA&mapclient=embed&cid=14145946805002975608
        // https://www.google.com/maps?ll=-25.74688,28.272453&z=16&t=m&hl=en-US&gl=ZA&mapclient=embed&cid=15851611822637527695
        // https://developers.google.com/places/place-id

        // 5DT HQ
        // ChIJXYsMqUNglR4RjzKxnfc8_Ns

        // 5DT.Inc
        // ChIJ74GfdVTd3IAReDnGs6t_UMQ

        const { place_name, place_id, text } = this.props

        const PLACE_NAME = place_name || '5DT'
        const PLACE_ID = place_id || ''
        const TEXT = text || ''
        const ICON = 'fa-map'

        const HREF = encodeURI(`https://www.google.com/maps/search/?api=1&query=${PLACE_NAME}&query_place_id=${PLACE_ID}`)

        return (
            <IconButton href={HREF} text={TEXT} icon={ICON} />
        )
    }
}

export default MapButton