// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import MediaQuery from 'react-responsive'
import Carousel from 'nuka-carousel'

import pic01 from 'assets/images/caffe.svg'
import pic02 from 'assets/images/caffe2.svg'
import pic03 from 'assets/images/cuda.svg'
import pic05 from 'assets/images/tensorflow-layout.svg'
import LOGO_OPENCV from 'assets/images/logo_opencv.svg'
import LOGO_ANDROID from 'assets/images/android-icon.svg'
import LOGO_RASPBERRY_PI from 'assets/images/raspberry-pi.svg'
import LOGO_MICROSOFT_WINDOWS from 'assets/images/microsoft-windows.svg'
import LOGO_LINUX from 'assets/images/linux-tux.svg'
import LOGO_APPLE from 'assets/images/apple.svg'
import LOGO_OPENCL from 'assets/images/OpenCL_Logo.svg'
import LOGO_SNAPDRAGON from 'assets/images/Qualcomm_snapdragon_logo.svg'
import LOGO_INTEL from 'assets/images/Intel-logo.svg'
import LOGO_ARM from 'assets/images/ARM_logo.svg'
import LOGO_DOCKER from 'assets/images/docker.svg'

function CarouselPlayer(props) {
    const HEIGHT = '100px'
    const { slidesToShow } = props
    return (
        <Carousel 
            renderCenterRightControls={null} 
            renderCenterLeftControls={null} 
            renderBottomCenterControls={null} 
            autoplay={true} 
            autoplayInterval={4000} 
            cellSpacing={10} 
            heightMode='max' 
            slideWidth={1.0} 
            slidesToShow={slidesToShow} 
            slidesToScroll={slidesToShow}
            swiping={true} 
            dragging={true} 
            width='100%' 
            wrapAround={true}
            // https://github.com/d3/d3-ease#easeElasticInOut
            // easing='easeBackIn'
            // easing='easeExpIn'
            easing='easeCubicIn'
        >

            <img src={pic01} width="100%" height={HEIGHT} alt="" />
            <img src={pic02} width="100%" height={HEIGHT} alt="" />
            <img src={pic03} width="100%" height={HEIGHT} alt="" />
            <img src={pic05} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_OPENCV} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_ANDROID} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_RASPBERRY_PI} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_MICROSOFT_WINDOWS} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_LINUX} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_APPLE} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_OPENCL} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_SNAPDRAGON} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_INTEL} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_ARM} width="100%" height={HEIGHT} alt="" />
            <img src={LOGO_DOCKER} width="100%" height={HEIGHT} alt="" />
        </Carousel>
    )
}

class TechnologyCarousel extends React.Component {

    render() {
        const PADDING = '50px'
        return (
            <section style={{padding: PADDING, backgroundColor: "white"}}>
                <MediaQuery query="(min-device-width: 1224px)">
                    <CarouselPlayer slidesToShow={5} />
                </MediaQuery>
                <MediaQuery query="(max-device-width: 1224px)">
                    <CarouselPlayer slidesToShow={4} />
                </MediaQuery>
            </section>
        )
    }
}

export default TechnologyCarousel