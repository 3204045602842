// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { target, href, text, icon } = props

    const TARGET = target || null
    const HREF = href || '#'
    const TEXT = text || ''
    const ICON = icon || 'fa-phone'

    const ICON_CLASS = `icon ${ICON}`
    return (
        <span>
            <a href={HREF} target={TARGET} className="button special"><span className={ICON_CLASS}></span> {TEXT}</a>
        </span>
    )
}
