import { graphql } from "gatsby"

import React from 'react'

// react-helmet
import Helmet from 'react-helmet'

// components
import TitleSection from 'components/TitleSection'
import SectionTitle from 'components/SectionTitle'

// react-select
import Select from 'react-select'

// axios
import axios from 'axios'

// recompose
import { compose, withState, withHandlers, pure } from 'recompose'

// validator
import validator from 'validator'

// content
import CONTENT from 'content/content'

// Internet Explorer does not seem to support the Fetch API.
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Sending_forms_through_JavaScript
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// https://www.npmjs.com/package/axios
// https://www.npmjs.com/package/request

const SubmitButton = compose(
    pure,
)(({ onClick, disabled }) => {

    const TITLE = CONTENT.contact.form.send.title
    const DISABLED = disabled === true
    return (
        <input disabled={DISABLED} type="submit" value={TITLE} className="special" style={{ margin: '10px' }} onClick={onClick} />
    )
})

const CountryField = compose(
    pure
)(({ disabled, value, onChange, countryList }) => {
    const VALUE = value === null || value === undefined ? null : { value: value, label: value }
    // https://reactjs.org/docs/forms.html
    const COUNTRY_LIST = countryList.map((countryName) => {
        return {
            value: countryName, label: countryName
        }
    })

    // https://react-select.com/styles#provided-styles-and-state
    const STYLES = {
        control: (styles, state) => {
            // console.log('control', styles, state)
            return {
                ...styles,
                backgroundColor: 'inherit',
                color: 'inherit',
                borderRadius: 0,
                border: 'inherit',
                '&:hover': { border: 'inherit' },
                paddingLeft: 10,
                boxShadow: 'none',
            }
        },
        option: (styles, state) => {
            // console.log('option', styles, state)
            return {
                ...styles,

                // backgroundColor: 'inherit',
                // color: 'inherit',
                // border: state.isSelected || state.isFocused ? '2px solid white' : 'none',

                backgroundColor: state.isSelected || state.isFocused ? 'white' : 'inherit',
                color: state.isSelected || state.isFocused ? 'black' : 'inherit',
            }
        },
        singleValue: (styles, state) => {
            // console.log('singleValue', styles, state)
            return {
                ...styles,
                color: 'inherit'
            }
        },
        menu: (styles, state) => {
            // console.log('menu', styles, state)
            return {
                ...styles,
                // backgroundColor: 'transparent'
                // backgroundColor: 'inherit'
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }
        },
        indicatorsContainer: (styles, state) => {
            // console.log('indicatorsContainer', styles, state)
            return {
                ...styles,
                display: 'none'
            }
        },
        input: (styles, state) => {
            // console.log('input', styles, state)
            return {
                ...styles,
                margin: 0,
                padding: 0,
                color: 'inherit',
            }
        },

        placeholder: (styles, state) => {
            // console.log('placeholder', styles, state)
            return {
                ...styles,
                color: 'inherit',
                marginLeft: 0,
                marginRight: 0,
                padding: 0,
            }
        }
    }

    // https://react-select.com/home
    // https://react-select.com/props
    return (
        <div className="field">
            <label htmlFor="country">{CONTENT.contact.form.country.title} *</label>
            <div className="field-input select-wrapper input-focus" style={{ padding: '0' }}>
                <Select
                    isDisabled={disabled}
                    isLoading={disabled}
                    styles={STYLES}
                    name="country"
                    id="country"
                    options={COUNTRY_LIST}
                    classNamePrefix="react-select"
                    onChange={onChange}
                    value={VALUE}
                />
            </div>
        </div>
    )
})

const EmailSection = props => {
    const HREF = encodeURI(`mailto:${CONTENT.email.address}?subject=${CONTENT.email.subject}&body=${CONTENT.email.body}`)
    return (
        <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>{CONTENT.contact.sections.email.heading}</h3>
            <a href={HREF}>{CONTENT.email.address}</a>
        </div>
    )
}

const AddressItem = props => {
    const { title, mapHref, telHref, address, tel } = props
    return (
        <div className="contact-method">
            <span className="icon alt fa-map-marker"></span>
            <h3>{title}</h3>
            <p>
                <span className="fa fa-phone fa-fw fa-lg"> </span> <a href={telHref}>{tel}</a>
                <br />
                <span className="fa fa-map fa-fw fa-lg"> </span> <a href={mapHref} target="_blank" rel="noopener noreferrer">{address}</a>
            </p>
        </div>
    )
}


const AmericasSection = props => {
    const PLACE_NAME = '5DT'
    // https://developers.google.com/maps/documentation/urls/guide
    const AMERICAS_MAP_HREF = encodeURI(`https://www.google.com/maps/search/?api=1&query=${PLACE_NAME}&query_place_id=${CONTENT.contact.americas.placeId}`)
    const AMERICAS_TEL_HREF = encodeURI(`tel:${CONTENT.contact.americas.callHref}`)
    return (
        <AddressItem title={CONTENT.contact.americas.title} mapHref={AMERICAS_MAP_HREF} address={CONTENT.contact.americas.address} telHref={AMERICAS_TEL_HREF} tel={CONTENT.contact.americas.call} />
    )
}

const WorldSection = props => {
    const PLACE_NAME = '5DT'
    // https://developers.google.com/maps/documentation/urls/guide
    const WORLD_MAP_HREF = encodeURI(`https://www.google.com/maps/search/?api=1&query=${PLACE_NAME}&query_place_id=${CONTENT.contact.world.placeId}`)
    const WORLD_TEL_HREF = encodeURI(`tel:${CONTENT.contact.world.callHref}`)
    return (
        <AddressItem title={CONTENT.contact.world.title} mapHref={WORLD_MAP_HREF} address={CONTENT.contact.world.address} telHref={WORLD_TEL_HREF} tel={CONTENT.contact.world.call} />
    )
}

const ContactArea = props => {
    return (
        <div>
            <div className="row">
                <div className="12u 6u(medium) 12u(small) 12u(xsmall)">
                    <EmailSection />
                </div>
                <div className="12u 6u(medium) 12u(small) 12u(xsmall)">
                    <AmericasSection />
                </div>
                <div className="12u 6u(medium) 12u(small) 12u(xsmall)">
                    <WorldSection />
                </div>
            </div>
        </div>
    )
}


function getErrorStyle(hasError) {
    if (hasError === null || hasError === undefined || hasError === false) {
        return null
    }
    return {
        border: '2px solid red',
        boxShadow: 'none',
    }
}

const FirstNameField = compose(
    pure
)(({ disabled, value, onChange, error }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="name">{CONTENT.contact.form.firstName.title} *</label>
            <input disabled={disabled} className="input-focus" type="text" name="name" id="name" value={value} onChange={onChange} style={getErrorStyle(error)} />
        </div>
    )
})

const LastNameField = compose(
    pure
)(({ disabled, value, onChange, error }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="surname">{CONTENT.contact.form.lastName.title} *</label>
            <input className="input-focus" type="text" name="surname" id="surname" value={value} onChange={onChange} disabled={disabled} style={getErrorStyle(error)} />
        </div>
    )
})

const EmailField = compose(
    pure
)(({ disabled, value, onChange, error }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="email">{CONTENT.contact.form.email.title} *</label>
            <input className="input-focus" type="email" name="email" id="email" value={value} onChange={onChange} disabled={disabled} style={getErrorStyle(error)} />
        </div>
    )
})

const TelField = compose(
    pure
)(({ disabled, value, onChange, error }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="tel">{CONTENT.contact.form.tel.title}</label>
            <input className="input-focus" type="tel" name="tel" id="tel" value={value} onChange={onChange} disabled={disabled} style={getErrorStyle(error)} />
        </div>
    )
})

const CompanyField = compose(
    pure
)(({ disabled, value, onChange }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="company">{CONTENT.contact.form.company.title}</label>
            <input className="input-focus" type="text" name="company" id="company" value={value} onChange={onChange} disabled={disabled} />
        </div>
    )
})

const MessageField = compose(
    pure
)(({ disabled, value, onChange }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <label htmlFor="message">{CONTENT.contact.form.message.title} *</label>
            <textarea className="input-focus" name="message" id="message" rows="6" value={value} onChange={onChange} disabled={disabled}></textarea>
        </div>
    )
})

const SubscribeField = compose(
    pure
)(({ disabled, value, onChange }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div className="field">
            <input className="input-focus" type="checkbox" id="newsletter" name="newsletter" value="newsletter" checked={value} onChange={onChange} disabled={disabled} />
            <label htmlFor="newsletter">{CONTENT.contact.form.subscribe.title}</label>
        </div>
    )
})

const AnotherMessage = compose(
    pure
)(({ onClick }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div>
            <input type="button" value={CONTENT.contact.form.retry.content} className="special" onClick={onClick} />
        </div>
    )
})

const SuccessMessage = compose(
    pure
)(({ onClick }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div>
            <p>{CONTENT.contact.form.success.content}</p>
            <AnotherMessage onClick={onClick} />
        </div>
    )
})

const ErrorMessage = compose(
    pure
)(({ onClick }) => {
    // https://reactjs.org/docs/forms.html
    return (
        <div>
            <p>{CONTENT.contact.form.error.content}</p>
            <AnotherMessage onClick={onClick} />
        </div>
    )
})

function isValidName(value) {
    if (value === null || value === undefined) {
        return false
    }
    if (value.length === 0) {
        return true
    }
    if (/^\s/.test(value)) {
        return false
    }
    if (/\s$/.test(value)) {
        return false
    }
    if (value.length < 2) {
        return false
    }
    if (/\d/.test(value)) {
        return false
    }
    return true
}

function isValidEmail(value) {
    if (value === null || value === undefined) {
        return false
    }
    if (value.length === 0) {
        return true
    }
    if (/^\s/.test(value)) {
        return false
    }
    if (/\s$/.test(value)) {
        return false
    }
    return validator.isEmail(value)
}

function isValidNumber(value) {
    if (value === null || value === undefined) {
        return false
    }
    if (value.length === 0) {
        return true
    }
    if (/^\s/.test(value)) {
        return false
    }
    if (/\s$/.test(value)) {
        return false
    }
    return validator.isMobilePhone(value)
}

function isFormValid(props) {
    const { firstNameError, lastNameError, emailError, phoneNumberError, firstName, lastName, email, country, message } = props
    return firstNameError === false &&
        lastNameError === false &&
        emailError === false &&
        phoneNumberError === false &&
        `${firstName}`.trim().length > 0 &&
        `${lastName}`.trim().length > 0 &&
        `${email}`.trim().length > 0 &&
        `${country}`.trim().length > 0 &&
        `${message}`.trim().length > 0
}

const ContactForm = compose(
    withState('submitting', 'setSubmitting', false),
    withState('success', 'setSuccess', null),
    withState('firstName', 'setFirstName', ''),
    withState('firstNameError', 'setFirstNameError', false),
    withState('lastName', 'setLastName', ''),
    withState('lastNameError', 'setLastNameError', false),
    withState('email', 'setEmail', ''),
    withState('emailError', 'setEmailError', false),
    withState('phoneNumber', 'setPhoneNumber', ''),
    withState('phoneNumberError', 'setPhoneNumberError', false),
    withState('company', 'setCompany', ''),
    withState('country', 'setCountry', ''),
    withState('message', 'setMessage', ''),
    withState('subscribe', 'setSubscribe', false),
    withHandlers({
        doRetry: ({ setSuccess }) => e => {
            setSuccess(null)
        },
        onChangeFirstName: ({ setFirstName, setFirstNameError }) => e => {
            const INPUT_VALUE = e.target.value
            setFirstName(INPUT_VALUE)
            setFirstNameError(!isValidName(INPUT_VALUE))
        },
        onChangeLastName: ({ setLastName, setLastNameError }) => e => {
            const INPUT_VALUE = e.target.value
            setLastName(INPUT_VALUE)
            setLastNameError(!isValidName(INPUT_VALUE))
        },
        onChangeEmail: ({ setEmail, setEmailError }) => e => {
            const INPUT_VALUE = e.target.value
            setEmail(INPUT_VALUE)
            setEmailError(!isValidEmail(INPUT_VALUE))
        },
        onChangePhoneNumber: ({ setPhoneNumber, setPhoneNumberError }) => e => {
            const INPUT_VALUE = e.target.value
            setPhoneNumber(INPUT_VALUE)
            setPhoneNumberError(!isValidNumber(INPUT_VALUE))
        },
        onChangeCompany: ({ setCompany }) => e => {
            setCompany(e.target.value)
        },
        onChangeCountry: ({ setCountry }) => e => {
            const INPUT_VALUE = e.value === null || e.value === undefined ? '' : e.value
            setCountry(INPUT_VALUE)
        },
        onChangeMessage: ({ setMessage }) => e => {
            const INPUT_VALUE = e.target.value
            setMessage(INPUT_VALUE)
        },
        onChangeSubscribe: ({ setSubscribe }) => e => {
            setSubscribe(e.target.checked)
        },
        submitForm: props => e => {
            e.preventDefault()
            const { setSubmitting, setSuccess, firstName, lastName, email, phoneNumber, company, country, message, subscribe } = props

            const IS_FORM_VALID = isFormValid(props)
            if (!IS_FORM_VALID) {
                setSuccess(false)
                return
            }

            const DATA = {
                firstName: validator.escape(firstName).trim(),
                lastName: validator.escape(lastName).trim(),
                email: validator.escape(validator.normalizeEmail(email)).trim(),
                phoneNumber: validator.escape(phoneNumber).trim(),
                company: validator.escape(company).trim(),
                country: validator.escape(country).trim(),
                message: validator.escape(message).trim(),
                subscribe: subscribe,
            }

            // Change this value to simulate sending (useful for making changes
            // to the visual interface).
            const NO_EMAIL = false

            setSubmitting(true)
            setSuccess(null)
            if (NO_EMAIL === true) {
                console.log('sending: ', DATA)
                setTimeout(() => {
                    setSubmitting(false)
                    setSuccess(Math.random() >= 0.5)
                }, 3000)
            }
            else {

                // Change this value to choose the testing email recipients or
                // the production email recipients.
                const TEST_EMAIL = true

                // Post the data via our AWS Gateway API to our AWS Lamda function.
                const URL = TEST_EMAIL === true ? 
                    "https://uroydslor3.execute-api.us-east-1.amazonaws.com/contact/test" :
                    "https://uroydslor3.execute-api.us-east-1.amazonaws.com/contact/sales"
                axios.post(URL, DATA)
                    .then(function (response) {
                        // console.log(response);
                        setSubmitting(false)
                        setSuccess(true)
                    })
                    .catch(function (error) {
                        console.log(error);
                        setSubmitting(false)
                        setSuccess(false)
                    });
            }
        }
    }),
    pure,
)(props => {

    const { submitForm, submitting, countryList,
        firstName, onChangeFirstName, firstNameError,
        lastName, onChangeLastName, lastNameError,
        email, onChangeEmail, emailError,
        phoneNumber, onChangePhoneNumber, phoneNumberError,
        company, onChangeCompany,
        country, onChangeCountry,
        message, onChangeMessage,
        subscribe, onChangeSubscribe,
        success, doRetry,
    } = props

    if (success === true) {
        return (
            <SuccessMessage onClick={doRetry} />
        )
    }
    else if (success === false) {
        return (
            <ErrorMessage onClick={doRetry} />
        )
    }

    const IS_FORM_VALID = isFormValid(props)

    const COUNTRY_LIST = countryList
    // https://fontawesome.com/v4.7.0/examples/
    const SUBMIT_BUTTON = submitting === false ? (
        <SubmitButton onClick={submitForm} disabled={!IS_FORM_VALID} />
    ) : (
            <span className="fa fa-spinner fa-pulse fa-3x fa-fw"></span>
        )
    return (
        <form method="post" action="#">
            <div className="fields">
                <div className="row">
                    <div className="6u 12u(small)">
                        <FirstNameField disabled={submitting} value={firstName} onChange={onChangeFirstName} error={firstNameError} />
                    </div>
                    <div className="6u 12u(small)">
                        <LastNameField disabled={submitting} value={lastName} onChange={onChangeLastName} error={lastNameError} />
                    </div>
                    <div className="6u 12u(small)">
                        <EmailField disabled={submitting} value={email} onChange={onChangeEmail} error={emailError} />
                    </div>
                    <div className="6u 12u(small)">
                        <CountryField disabled={submitting} value={country} onChange={onChangeCountry} countryList={COUNTRY_LIST} />
                    </div>
                    <div className="6u 12u(small)">
                        <TelField disabled={submitting} value={phoneNumber} onChange={onChangePhoneNumber} error={phoneNumberError} />
                    </div>
                    <div className="6u 12u(small)">
                        <CompanyField disabled={submitting} value={company} onChange={onChangeCompany} />
                    </div>
                    <div className="12u">
                        <MessageField disabled={submitting} value={message} onChange={onChangeMessage} />
                    </div>
                    <div className="12u">
                        <SubscribeField disabled={submitting} value={subscribe} onChange={onChangeSubscribe} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    {SUBMIT_BUTTON}
                </div>
            </div>
        </form>
    )
})

export default (props) => {

    const COUNTRY_LIST = props.data.allCountriesCsv.edges.map(({ node }) => node.Name)

    return (
        <div>
            <Helmet>
                <title>{CONTENT.index.seo.title}</title>
                <meta name="description" content={CONTENT.contact.title} />
            </Helmet>

            <section id="contact">
                <TitleSection>
                    <div className="row">
                        <div className="12u">
                            <SectionTitle nomajor>{CONTENT.contact.title}</SectionTitle>
                        </div>
                        <div className="8u 12u(medium)">
                            <ContactForm countryList={COUNTRY_LIST} />
                        </div>
                        <div className="4u 12u(medium)">
                            <ContactArea />
                        </div>
                    </div>
                </TitleSection>
            </section>

        </div>
    )
}

// https://www.gatsbyjs.org/docs/querying-with-graphql/
// https://www.gatsbyjs.org/docs/static-query/
export const query = graphql`
  query {
    allCountriesCsv {
      edges {
        node {
          Name
        }
      }
    }
  }
`
