import React from 'react'
import Helmet from 'react-helmet'
import BannerLanding from 'components/BannerLanding'
import TitleSection from 'components/TitleSection'
import TechnologyCarousel from 'components/TechnologyCarousel'
import SolutionCarousel from 'components/SolutionCarousel'
import ProductCarousel from 'components/ProductCarousel'
import ServiceCarousel from 'components/ServiceCarousel'
import SectionTitle from 'components/SectionTitle'
import SectionHeading from 'components/SectionHeading'

import PRIVACY_POLICY from 'assets/pdf/privacy_policy.pdf'

// content
import CONTENT from 'content/content'

const PrivacyPolicyLink = (props) => {
    return (
        <a
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer">
            <i>here</i>.
        </a>
    )
}

export default (props) => {
    return (
        <div>
            <Helmet>
                <title>{CONTENT.landing.seo.title}</title>
                <meta name="description" content={CONTENT.landing.seo.description} />
            </Helmet>

            <BannerLanding id="home" />

            <div id="main">

                <TitleSection id="about">
                    <SectionTitle>{CONTENT.about.title2}</SectionTitle>
                    <SectionHeading>{CONTENT.about.about}</SectionHeading>
                    <SectionHeading>{CONTENT.about.privacyPolicy}<PrivacyPolicyLink /></SectionHeading>
                </TitleSection>

                <section className="spotlights">
                </section>

                <TitleSection id="services">
                    <SectionTitle>{CONTENT.services.title}</SectionTitle>
                    <SectionHeading>{CONTENT.services.about}</SectionHeading>
                </TitleSection>

                <section className="spotlights">
                    <ServiceCarousel />
                </section>

                <TitleSection id="solutions">
                    <SectionTitle>{CONTENT.solutions.title}</SectionTitle>
                    <SectionHeading>{CONTENT.solutions.about}</SectionHeading>
                </TitleSection>

                <section className="spotlights">
                    <SolutionCarousel />
                </section>

                <TitleSection id="products">
                    <SectionTitle>{CONTENT.products.title}</SectionTitle>
                    <SectionHeading>{CONTENT.products.about}</SectionHeading>
                </TitleSection>

                <section className="spotlights">
                    <ProductCarousel />
                </section>

                <TitleSection id="technology">
                    <SectionTitle>{CONTENT.technology.title}</SectionTitle>
                    <SectionHeading>{CONTENT.technology.about}</SectionHeading>
                </TitleSection>

                <section className="spotlights">
                    <TechnologyCarousel />
                </section>

            </div>

        </div>

    )
}