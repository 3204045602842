import React from 'react'
import { navigate } from 'gatsby'
import ReactRevealText from 'react-reveal-text'

import CONTENT from 'content/content'

function clickItem(href, onToggleMenu) {
    navigate(href)
    onToggleMenu()
    return false
}

function MenuItem(props) {
    const { href, isMenuVisible, onToggleMenu, title, big1, big2, trademark } = props
    const BIG1 = big1 === null || big1 === undefined ? false : true
    const BIG2 = big2 === null || big2 === undefined ? false : true
    const TRADEMARK = trademark === null || trademark === undefined ? false : true
    const TRADEMARK_STYLE = TRADEMARK === true ? 'trademark' : null
    return BIG1 ? (
        <div 
            role="link" 
            onClick={() => clickItem(href, onToggleMenu)} 
            className={`button fit ${TRADEMARK_STYLE}`}>
            <ReactRevealText show={isMenuVisible} transitionTime={250}>{title}</ReactRevealText>
        </div>
    ) : BIG2 ? (
        <div 
            role="link" 
            onClick={() => clickItem(href, onToggleMenu)} 
            className="button special">
            {title}
        </div>
    ) : (
        <li><a onClick={() => clickItem(href, onToggleMenu)} href={href}>{title}</a></li>
    )
}


class Menu extends React.Component {

    render() {
        const { isMenuVisible, onToggleMenu } = this.props

        return (
            <nav id="menu">
                <div className="inner">
                    <ul className="actions vertical">
                        <MenuItem href="/product1" isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.product1.title} big1 trademark />
                    </ul>
                    <ul className="links">
                        <MenuItem href={CONTENT.landing.sections.services.href} isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.services.title} />
                        <MenuItem href={CONTENT.landing.sections.solutions.href} isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.solutions.title} />
                        <MenuItem href={CONTENT.landing.sections.products.href} isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.products.title} />
                        <MenuItem href={CONTENT.landing.sections.technology.href} isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.technology.title} />
                        <MenuItem href={CONTENT.landing.sections.about.href} isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.about.title} />
                        <MenuItem href="/#contact_us" isMenuVisible={isMenuVisible} onToggleMenu={onToggleMenu} title={CONTENT.contact.title} big2 />
                    </ul>
                </div>
                <a 
                    className="close" 
                    onClick={(e) => {
                        e.preventDefault()
                        onToggleMenu()
                    }} 
                    href="#contact_us">Close</a>
            </nav>
        )
    }
}

export default Menu
