import React from 'react'
import { Link } from 'gatsby'

function TileImpl(props) {
    const { id, title, sub, href } = props
    return (
        <article id={id} className="overlay">
            <header className="major">
                <h3>{title}</h3>
                <p>{sub}</p>
            </header>
            <Link to={href} className="link primary"></Link>
        </article>
    )
}

class Tile extends React.Component {

    render() {
        const { id, src, title, sub, href } = this.props
        return (
            <TileImpl id={id} src={src} title={title} sub={sub} href={href} />
        )
    }
}

export default Tile
