// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { children } = props
    return (
        <div>
            {React.Children.map(children, (value) => value)}
        </div>
    )
}
