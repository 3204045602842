import React from 'react'

// content
import CONTENT from 'content/content'

function Logo(props) {
    const CODE_STYLE = {
        // backgroundColor: "white", 
        fontSize: "12pt"
    }
    const RED = {
        color: "red"
    }
    const GREEN = {
        color: "green"
    }
    const BLUE = {
        color: "blue"
    }
    return (
        <span><code style={CODE_STYLE}><strong style={RED}>5</strong><strong style={GREEN}>D</strong><strong style={BLUE}>T</strong></code></span>
    )
}

export default (props) => {
    return (
        <footer id="footer">
            <div className="inner">
                <div className="row">
                    <div className="12u">
                        <ul className="copyright">
                            <li>{CONTENT.footer.attribution.design.title}: <a href={CONTENT.footer.attribution.design.link}>{CONTENT.footer.attribution.design.name}</a></li>
                            <li>{CONTENT.footer.attribution.logos.title}: <a href={CONTENT.footer.attribution.logos.link}>{CONTENT.footer.attribution.logos.name}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="12u">
                        <ul className="copyright">
                            <li><span className="fa fa-copyright fa-fw fa-lg" /> <Logo /> {CONTENT.footer.copyright}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
