// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import Zoom from 'react-reveal/Zoom'
import { navigate } from 'gatsby'

// export default (props) => {
//     const { description, title, src, href, action } = props
//     return (
//         <Zoom>
//             <div className='product-card-base product-card' style={{width: '90%', height: '100%', padding: '10px'}}>
//                 <div className="row">
//                     <div className="12u">
//                         <div className="row">
//                             <div className="12u">
//                                 <div className='product-card-image-outer'>
//                                     <img src={src} alt={description} className='product-card-image-inner' />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="12u">
//                                 <div className='product-card-description-box'>
//                                     <div className='product-card-name'>
//                                         {title}
//                                     </div>
//                                     <p className='product-card-description'>
//                                         {description}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="12u">
//                                 <div className='product-card-action-box' style={{padding: '10px'}}>
//                                     <div role="link"
//                                         onClick={() => navigate(href)}
//                                         className={'button special'}
//                                         style={{ width: '100%' }}
//                                     >
//                                         {action}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Zoom>
//     )
// }

export default (props) => {
    const { description, title, src, href, action } = props
    return (
        <Zoom>
            <div className='product-card-base product-card'>
                <div className='product-card-description-box'>
                    <div className="row">
                        <div className="12u">
                            <div className='product-card-name'>
                                {title}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='product-card-description-box'>
                    <div className="row">
                        <div className="12u">
                            <div className='product-card-description'>
                                {description}
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='product-card-action-box'>
                    <div className="row">
                        <div className="12u">
                            <div role="link"
                                onClick={() => navigate(href)}
                                className={'button special'}
                                style={{ width: '100%' }}
                            >
                                {action}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-card-image-outer product-card-shadow">
                    <div className="row">
                        <div className="12u">
                            <img src={src} alt={description} className='product-card-image-inner' />
                        </div>
                    </div>
                </div>
                <div className='product-card-description-box'>
                    <div className="row">
                        <div className="12u">
                            <div className='product-card-description'>
                                {description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Zoom>
    )
}