// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { src, alt } = props
    const ALT = alt === null || alt === undefined ? "" : alt

    return (
        <img src={src} alt={ALT} className={`image shadow`} width='100%' height='100%' />
    )
}
