import React from 'react'
import ReactRevealText from 'react-reveal-text'

import { compose, withState, lifecycle, pure } from 'recompose'

import CONTENT from 'content/content'

export default compose(
    withState('show', 'setShow', false),
    lifecycle({
        componentDidMount() {
            setTimeout(() => { this.props.setShow(true) }, 200)
        }
    }),
    pure
)(props => {
    const { show } = props
    return (
        <section id="banner" className="landing">
            <div className="inner">
                <header className="major">
                    <h1><ReactRevealText show={show}>{CONTENT.landing.banner.title}</ReactRevealText></h1>
                </header>
                <div className="content">
                    <h2><ReactRevealText show={show}>{CONTENT.landing.banner.sub}</ReactRevealText></h2>
                </div>
            </div>
        </section>
    )
})
