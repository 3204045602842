import React from 'react'
import TitleSection from './TitleSection'
import EmailButton from './EmailButton'
import CallButton from './CallButton'
import MapButton from './MapButton'
import SectionTitle from './SectionTitle'
import SectionHeading from './SectionHeading'
import ContactFormButton from './ContactFormButton'

// content
import CONTENT from 'content/content'

function ContactAmericas(props) {
    return (
        <div>
            <div className="row">
                <div className="12u">
                    <h4>{CONTENT.contact.americas.title}</h4>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <p>
                        <CallButton number={CONTENT.contact.americas.callHref} text={CONTENT.contact.americas.call} label={CONTENT.contact.americas.title} />
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <p>
                        <MapButton place_name="5DT" place_id={CONTENT.contact.americas.placeId} text={CONTENT.contact.maps.address} />
                    </p>
                </div>
            </div>
        </div>
    )
}
function ContactWorld(props) {
    return (
        <div>
            <div className="row">
                <div className="12u">
                    <h4>{CONTENT.contact.world.title}</h4>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <p>
                        <CallButton number={CONTENT.contact.world.callHref} text={CONTENT.contact.world.call} label={CONTENT.contact.world.title} />
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <p>
                        <MapButton place_name="5DT" place_id={CONTENT.contact.world.placeId} text={CONTENT.contact.maps.address} />
                    </p>
                </div>
            </div>
        </div>
    )
}

function ContactEmail(props) {
    return (
        <div>
            <div className="row">
                <div className="12u">
                    <h4>{CONTENT.contact.sections.email.heading}</h4>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <p>
                        <EmailButton to={CONTENT.email.address} text={CONTENT.email.address} />
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <ContactFormButton />
                </div>
            </div>
        </div>
    )
}

function ContactLinks(props) {
    return (
        <div>
            <div className="row">
                <div className="12u">
                    <h4>{CONTENT.contact.sections.links.heading}</h4>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <ul className="icons">
                        <li><span className="icon alt fa-youtube"> </span><a href={CONTENT.links.youtube.href} target="_blank" rel="noopener noreferrer"><span className="label">{CONTENT.links.youtube.title}</span></a></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="12u">
                    <ul className="icons">
                        <li><span className="icon alt fa-globe"> </span><a href={CONTENT.links.main.href} target="_blank" rel="noopener noreferrer"><span className="label">{CONTENT.links.main.title}</span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function ContactDetails2(props) {
    return (
        <div style={{ paddingTop: '0px' }}>

            <div className="row">
                <div className="3u 12u(xsmall) 6u(small) 4u(medium)">
                    <ContactEmail />
                </div>
                <div className="3u 12u(xsmall) 6u(small) 4u(medium)">
                    <ContactAmericas />
                </div>
                <div className="3u 12u(xsmall) 6u(small) 4u(medium)">
                    <ContactWorld />
                </div>
                <div className="3u 12u(xsmall) 6u(small) 4u(medium)">
                    <ContactLinks />
                </div>
            </div>

        </div>
    )
}

export default (props) => {
    return (
        <TitleSection id="contact_us" overlay>
            <SectionTitle>{CONTENT.contact.title}</SectionTitle>
            <SectionHeading>{CONTENT.contact.sub}</SectionHeading>
            <ContactDetails2 />
        </TitleSection>
    )
}
