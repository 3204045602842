// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { nomajor, center, children } = props
    const NOMAJOR = nomajor === null || nomajor === undefined ? "major" : null
    const CENTER = center === null || center === undefined ? null : "special"
    const HEADER_CLASS_NAME = `${NOMAJOR} ${CENTER}`
    return (
        <header className={HEADER_CLASS_NAME}>
            <h2>{React.Children.map(children, (value) => value)}</h2>
        </header>
    )
}
