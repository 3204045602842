import React from 'react'
import Helmet from 'react-helmet'
import Banner from 'components/Banner'
import Tile from 'components/Tile'
import BackgroundVideo from 'components/BackgroundVideo'
import Contact from 'components/Contact'

import picAbout from 'assets/images/pic03.png'
import picTech from 'assets/images/pic07.png'
import picSolutions from 'assets/images/pic05.png'
import picServices from 'assets/images/pic08.png'
import picProducts from 'assets/images/pic08.png'

import CONTENT from 'content/content'

export default (props) => {
    return (
        <div>
            <Helmet>
                <title>{CONTENT.index.seo.title}</title>
                <meta name="description" content={CONTENT.index.seo.description} />
            </Helmet>

            <BackgroundVideo />

            <Banner id="home" showVideo={false} />

            <div id="main">
                <section className="tiles">
                    <Tile id="about" src={picAbout} title={CONTENT.about.title} sub={CONTENT.about.sub} href={CONTENT.landing.sections.about.href} />
                    <Tile id="product1" src={picTech} title={CONTENT.product1.title} sub={CONTENT.product1.sub} href="/product1" />
                    <Tile id="product2" src={picTech} title={CONTENT.product2.title} sub={CONTENT.product2.sub} href="/product2" />
                    <Tile id="product3" src={picTech} title={CONTENT.product3.title} sub={CONTENT.product3.sub} href="/product3" />
                    <Tile id="product4" src={picTech} title={CONTENT.product4.title} sub={CONTENT.product4.sub} href="/product4" />
                    <Tile id="product5" src={picTech} title={CONTENT.product5.title} sub={CONTENT.product5.sub} href="/product5" />
                    <Tile id="services" src={picServices} title={CONTENT.services.title} sub={CONTENT.services.sub} href={CONTENT.landing.sections.services.href} />
                    <Tile id="solutions" src={picSolutions} title={CONTENT.solutions.title} sub={CONTENT.solutions.sub} href={CONTENT.landing.sections.solutions.href} />
                    <Tile id="products" src={picProducts} title={CONTENT.products.title} sub={CONTENT.products.sub} href={CONTENT.landing.sections.products.href} />
                    <Tile id="technology" src={picProducts} title={CONTENT.technology.title} sub={CONTENT.technology.sub} href={CONTENT.landing.sections.technology.href} />
                </section>
            </div>

            <Contact />

        </div>
    )
}
