// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import ProductCard from './ProductCard2'

import PIC_SAFETY from 'assets/images/pic01.png'
import PIC_MONITORING from 'assets/images/pic06.png'
import PIC_ADVERTISING from 'assets/images/pic07.png'

import CONTENT from 'content/content'

export default (props) => {
    return (
        <section className="inner">
            <div className="row">
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_SAFETY} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.solutions.group1.title} description={CONTENT.solutions.group1.sub} />
                </div>
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_MONITORING} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.solutions.group2.title} description={CONTENT.solutions.group2.sub} />
                </div>
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_ADVERTISING} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.solutions.group3.title} description={CONTENT.solutions.group3.sub} />
                </div>
            </div>
        </section>
    )
}
