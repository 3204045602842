// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import ProductCard from './ProductCard2'

import PIC_SYNTHETIC from 'assets/images/pic10.png'
import PIC_AI_MODELS from 'assets/images/caffe.svg'
import PIC_DEVELOPMENT from 'assets/images/pic08.png'

import CONTENT from 'content/content'

export default (props) => {
    return (
        <section className="inner">
            <div className="row">
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_SYNTHETIC} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.services.group1.title} description={CONTENT.services.group1.sub} />
                </div>
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_DEVELOPMENT} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.services.group3.title} description={CONTENT.services.group3.sub} />
                </div>
                <div className="4u 12u(small) 6u(medium)">
                    <ProductCard src={PIC_AI_MODELS} action={CONTENT.actions.requestInfo} href={CONTENT.contact.href} title={CONTENT.services.group2.title} description={CONTENT.services.group2.sub} />
                </div>
            </div>
        </section>
    )
}