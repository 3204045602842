import React from 'react'

// react-helmet
import Helmet from 'react-helmet'

// gatsby
import { withPrefix } from 'gatsby'

// assets
import 'assets/scss/main.scss'

// components
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

// Site not working on IE11 for some reason.
// https://stackoverflow.com/questions/33828840/symbol-is-undefined-in-ie-after-using-babel
// https://www.gatsbyjs.org/docs/browser-support/
// https://github.com/facebook/react/issues/8379
// https://github.com/gatsbyjs/gatsby/issues/7003
// https://github.com/gatsbyjs/gatsby/issues/7003#issuecomment-426739031
// https://github.com/gatsbyjs/gatsby/issues/8522
// https://github.com/facebook/react/issues/8379
// https://reactjs.org/docs/javascript-environment-requirements.html
// https://reactjs.org/docs/react-dom.html#browser-support
// import 'babel-polyfill'

class MainLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
    }

    componentDidMount () {
        // console.log('componentDidMount')
        this.timeoutId = setTimeout(() => {
            // console.log('removing is-loading')
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        // console.log('componentWillUnmount')
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        // console.log('handleToggleMenu')
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <Helmet>
                    <link rel="stylesheet" href={withPrefix('skel.css')} />
                </Helmet>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu.bind(this)} />
                    {children}
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu.bind(this)} isMenuVisible={this.state.isMenuVisible} />
            </div>
        )
    }
}

export default MainLayout
