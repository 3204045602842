// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'

export default (props) => {
    const { children } = props
    return (
        <h5>
            <code>{React.Children.map(children, (value) => value)}</code>
        </h5>
    )
}
