import React from 'react'
import Banner from './Banner'

import CONTENT from 'content/content'

export default (props) => {
    return (
        <Banner title={CONTENT.product5.title} sub={CONTENT.product5.sub} action={CONTENT.actions.learnMore} />
    )
}
