// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import IconButton from './IconButton'

import CONTENT from 'content/content'

export default (props) => {
    const { number, text } = props

    const TEXT = text || CONTENT.actions.callUs
    const NUMBER = number || CONTENT.world.callHref

    const HREF = `tel:${NUMBER}`
    return (
        <IconButton href={HREF} text={TEXT} icon="fa-phone" />
    )
}
