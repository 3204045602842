import React from 'react'

// react-helmet
import Helmet from 'react-helmet'

// components
import BannerProduct3 from 'components/BannerProduct3'
import BackgroundVideo from 'components/BackgroundVideo'
import TitleSection from 'components/TitleSection'
import SectionTitle from 'components/SectionTitle'
// import SectionHeading from 'components/SectionHeading'
import SectionCaption from 'components/SectionCaption'
import SectionImage from 'components/SectionImage'
import SectionBody from 'components/SectionBody'
import SectionVideo from 'components/SectionVideo'
// import EmailButton from 'components/EmailButton'

// gatsby
import { navigate } from 'gatsby'

// assets
import PRODUCT_IMAGE1 from 'assets/images/product3-a.png'
import PRODUCT_IMAGE2 from 'assets/images/product3-b.png'
import PRODUCT_DATASHEET from 'assets/pdf/product3-a.pdf'


// content
import CONTENT from 'content/content'

const ContactButton = (props) => {
    return (
        <div role="link"
            onClick={() => navigate(CONTENT.contact.href)}
            className={'button special'}
        >
            {CONTENT.actions.contactUs}
        </div>
    )
}

const DatasheetLink = (props) => {
    return (
        <a
            href={PRODUCT_DATASHEET}
            target="_blank"
            rel="noopener noreferrer">
            here.
        </a>
    )
}

const FeaturesContent = (props) => {
    return (
        <SectionBody>
            <ul>
                {CONTENT.product3.features.map((value, index) => <li key={`feature${index}`}>{value}</li>)}
            </ul>
        </SectionBody>
    )
}

const ApplicationsContent = (props) => {
    return (
        <SectionBody>
            <ul>
                {CONTENT.product3.applications.map((value, index) => <li key={`application${index}`}>{value}</li>)}
            </ul>
        </SectionBody>
    )
}

export default (props) => {
    return (
        <div>
            <Helmet>
                <title>{CONTENT.product3.title}</title>
                <meta name="description" content={CONTENT.product3.sub} />
            </Helmet>

            <BannerProduct3 />
            <div id="main">
                <TitleSection id="about" overlay center>
                    <div className="row">
                        <div className="12u">
                            <SectionTitle nomajor>{CONTENT.product3.sections.overview}</SectionTitle>
                            <SectionBody>
                                <p>{CONTENT.product3.about}</p>
                            </SectionBody>
                        </div>
                    </div>
                </TitleSection>
                <TitleSection overlay>
                    <div className="row">
                        <div className="6u 12u(small)">
                            <SectionTitle nomajor>{CONTENT.product3.sections.features}</SectionTitle>
                            <FeaturesContent />
                        </div>
                        <div className="6u 12u(small)">
                            <SectionTitle nomajor>{CONTENT.product3.sections.applications}</SectionTitle>
                            <ApplicationsContent />
                        </div>
                    </div>
                </TitleSection>
                <TitleSection overlay center>
                    <div className="row">
                        <div className="12u">
                            <SectionTitle nomajor>{CONTENT.product3.sections.system}</SectionTitle>
                        </div>
                        <div className="12u">
                            <SectionImage src={PRODUCT_IMAGE2} alt={CONTENT.images3.system.caption} />
                            <SectionCaption>{CONTENT.images3.system.caption}</SectionCaption>
                        </div>
                    </div>
                </TitleSection>
                <TitleSection overlay center>
                    <div className="row">
                        <div className="12u">
                            <SectionTitle nomajor>{CONTENT.product3.sections.datasheet}</SectionTitle>
                        </div>
                        <div className="12u">
                            <SectionBody>
                                <p>{CONTENT.product3.datasheet}<DatasheetLink /></p>
                            </SectionBody>
                        </div>
                    </div>
                    <div className="row">
                        <div className="12u">
                            <ContactButton />
                        </div>
                    </div>
                </TitleSection>
            </div>

        </div>
    )
}

// https://github.com/ajlkn/skel/blob/master/docs/skel-layout.md#usage-1
// https://github.com/ajlkn/skel/blob/master/docs/skel-layout.md#how-it-works
