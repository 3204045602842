import React from 'react'

// react-helmet
import Helmet from 'react-helmet'

// components
import TitleSection from 'components/TitleSection'
import SectionTitle from 'components/SectionTitle'
import SectionHeading from 'components/SectionHeading'

// content
import CONTENT from 'content/content'

export default (props) => {
    return (
        <div>
            <Helmet>
                <title>{CONTENT.index.seo.title}</title>
                <meta name="description" content={CONTENT.error.title} />
            </Helmet>

            <div id="main">
                <TitleSection overlay center>
                    <div className="row">
                        <div className="12u">
                            <SectionTitle nomajor>{CONTENT.error.title}</SectionTitle>
                            <SectionHeading>
                                <p>{CONTENT.error.message}</p>
                            </SectionHeading>
                        </div>
                    </div>
                </TitleSection>
            </div>

        </div>
    )
}

// https://github.com/ajlkn/skel/blob/master/docs/skel-layout.md#usage-1
// https://github.com/ajlkn/skel/blob/master/docs/skel-layout.md#how-it-works
