// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
// import Typist from 'react-typist'
import Zoom from 'react-reveal/Zoom'

export default (props) => {
    const { id, children, overlay, center } = props
    const OVERLAY = overlay === null || overlay === undefined ? null : "focus"
    const CENTER = center === null || center === undefined ? null : "special"
    const SECTION_CLASS_NAME = `${OVERLAY} ${CENTER}`
    return (
        <section id={id} className={SECTION_CLASS_NAME}>
            <div className="inner">
                {React.Children.map(children, (value, index) => <Zoom key={`child${index}`}>{value}</Zoom>)}
            </div>
        </section>
    )
}