import React from 'react'

// Find reasons for content here:
// https://drive.google.com/drive/folders/1yIYoT6wZogTNeotaq6tBc3N69khouujx?usp=sharing

const EMAIL_SUBJECT = '📌 5DT.AI Contact Form'
const EMAIL_SUBJECT2 = '📌 5DT.AI Contact Form: DriveVUE™'
const EMAIL_SUBJECT3 = '📌 5DT.AI Contact Form: FeverVUE™'
const EMAIL_SUBJECT4 = '📌 5DT.AI Contact Form: SimVUE™'
const EMAIL_SUBJECT5 = '📌 5DT.AI Contact Form: SecuriVUE™'
const EMAIL_SUBJECT6 = '📌 5DT.AI Contact Form: ScreenVUE™'

const PRODUCT1_OVERVIEW = [
    'The 5DT DriveVUE™ Operator Attentiveness Evaluation System ', <em key='patent'>(US Patents Pending)</em>, 
    ' is an Artificial Intelligence (AI) system that uses a variety of sensors and machine learning algorithms to detect and track ', 
    <strong key='objects'>objects</strong>, 
    ' and to evaluate ', 
    <strong key='behaviours'>behaviours</strong>, 
    ' of operators or other persons in a scene. It detects and evaluates operator attentiveness and operator drowsiness. The system sends alerts to the operator and/or to a control centre.',
]
const PRODUCT2_OVERVIEW = [
    'The 5DT FeverVUE COVID-19 screening system uses a screening application and a measurement station to screen employees and visitors for medical conditions and COVID-19 symptoms, and to control access to a site accordingly. The screening application allows employees and visitors to log their ',
    <strong key='symptoms'>symptoms</strong>,
    ' from home, and the measurement station measures their ',
    <strong key='temperature'>temperature</strong>,
    ' and ensures that they are wearing a face mask. Employees and visitors will only be allowed to access the site if all requirements have been met.',
    'The screening application and the measurement station can be used together or as stand-alone systems.'
]
const PRODUCT3_OVERVIEW = [
    '5DT SimVUE™ system is an evaluation system for simulator occupants. The system uses a camera and artificial intelligence (AI) algorithms to perform eye tracking, head tracking, facial recognition and to detect where body parts are situated.'
]
const PRODUCT4_OVERVIEW = [
    'The 5DT SecuriVUE™ system (US Patents Pending) is an artificial intelligence (AI) enhanced system for the management of video feeds. The system uses AI algorithms to perform a wide range of automated detections, identifications and trackings.'
]
const PRODUCT5_OVERVIEW = [
    'The 5DT ScreenVUE™ system is an evaluation system for video wall viewers and baggage scanner operators. The system uses a camera and an artificial intelligence (AI) eye tracking algorithm to determine exactly where a video wall operator is looking.'
]

const EMAIL_BODY = [
    'We would like to communicate with you about our products and services. In order to do so, please provide us with the following information and your questions and/or inputs.',
    '',
    'Firstname:',
    '',
    'Lastname:',
    '',
    'Company:',
    '',
    'Country:',
    '',
    'Phone Number (mobile preferred):',
    '',
    'Questions / Inputs:',
    '',
    'Thank you'
]
        
export default {
    index: {
        seo: {
            title: '5DT (Fifth Dimension Technologies)',
            description: '5DT Machine Learning Solutions',
        },
        banner: {
            title: '5DT.AI',
            sub: 'End-to-end machine learning solutions with a focus on machine vision.'
        },
    },
    landing: {
        seo: {
            title: '5DT (Fifth Dimension Technologies)',
            description: '5DT Machine Learning Solutions',
        },
        banner: {
            title: '5DT.AI',
            sub: 'Machine learning capabilities, products and services offered by 5DT.',
        },
        sections: {
            services: {
                href: '/landing#services'
            },
            solutions: {
                href: '/landing#solutions'
            },
            technology: {
                href: '/landing#technology'
            },
            products: {
                href: '/landing#products'
            },
            about: {
                href: '/landing#about'
            },
        }
    },
    about: {
        title: 'About',
        title2: 'About 5DT',
        sub: 'Trusted Experience',
        about: '5DT (Fifth Dimension Technologies) is a high tech company specializing in Machine Learning and Training Simulators for the Mining, Construction, Trucking, Aerospace and Defense Industries. 5DT has been in business for more than 25 years. It has more than 200 simulators deployed worldwide. 5DT has developed simulators for more than 125 different vehicles, machines or aircraft from more than 35 manufacturers.',
        privacyPolicy: ['Our privacy policy may be downloaded by clicking ']
    },
    services: {
        title: 'Services',
        sub: 'End-to-end Machine Learning Development',
        about: 'We offer an end-to-end Machine Learning Development service, with an extensive set of capabilities.',
        group1: {
            title: 'Synthetic Data',
            sub: 'We create labelled synthetic images and video.',
        },
        group2: {
            title: 'Pre-Trained AI Models',
            sub: 'AI models trained using captured and/or synthetic data, supporting industry standard formats.',
        },
        group3: {
            title: 'Application Development',
            sub: 'Custom Machine Learning applications to solve a variety of problems, such as object detection, object counting, and behaviour detection.',
        },
    },
    solutions: {
        title: 'Solutions',
        sub: 'Custom Solutions',
        about: 'We offer a range of Machine Learning solutions that you can adopt in your business.',
        email: {
            subject: EMAIL_SUBJECT,
            body: EMAIL_BODY.join('\n'),
        },
        group1: {
            title: 'Safety',
            sub: 'Solutions that detect unsafe situations and behaviour.',
        },
        group2: {
            title: 'Monitoring',
            sub: 'Solutions that monitor situations or areas for designated activities.',
        },
        group3: {
            title: 'Marketing',
            sub: 'Solutions that let potential buyers engage with your product.',
        },
    },
    products: {
        title: 'Products',
        sub: 'Our Machine Learning Products',
        about: 'Our products can be tailored according to your requirements.',
        email: {
            subject: EMAIL_SUBJECT,
            body: EMAIL_BODY.join('\n'),
        },
        group1: {
            title: 'DriveVUE™',
            sub: 'Operator Attentiveness Evaluation System',
        },
        group2: {
            title: 'FeverVUE™',
            sub: 'Screening Application and Temperature Measurement System',
        },
        group3: {
            title: 'SimVUE™',
            sub: 'Simulator Occupant Tracking & Evaluation System',
        },
        group4: {
            title: 'SecuriVUE™',
            sub: 'AI Enhanced Video Management System',
        },
        group5: {
            title: 'ScreenVUE™',
            sub: 'Video Wall & Baggage Scanner Operator Evaluation System',
        },
    },
    technology: {
        title: 'Technology',
        sub: 'Latest Platforms and Industry Standards',
        about: 'We use the latest deep learning and machine vision methods, and support the latest industry standards. Our Machine Learning solutions are GPU-optimized and support a wide range of platforms, SDKs, game engines and devices, including mobile phones.',
    },
    product1: {
        title: 'DriveVUE™',
        sub: 'Operator Attentiveness Evaluation System',
        patent: 'US Patents Pending',
        about: PRODUCT1_OVERVIEW,
        sections: {
            overview: 'Product Overview',
            features: 'Features',
            applications: 'Application Areas',
            system: 'System Description',
            datasheet: 'Data Sheet',
        },
        features: [
            'Mobile Phone Talking Detection',
            'Mobile Phone Use Detection (e.g. Texting)',
            'Drowsiness Detection',
            'Hands Near Face Detection',
            'Eyes Off Road Detection',
            'Personal Protective Equipment (PPE) Detection',
            'Facial Recognition of Operators/Occupants',
            'Hours of Service Logging',
            'Seat Belt Detection',
            'Number of Occupants Detection',
            'Unauthorized Occupant Detection',
            'Unauthorized Object Detection (e.g. Guns)',
            'Cargo Monitoring',
            'Aggressive Driving and Braking Detection',
            'Mirror Check Detection',
            'Real-Time or Off-Line Processing',
            'Operator/Occupant Images May be Masked',
            'Multi Camera Support',
            'Unauthorized Actions Detection (e.g. Drinking, Smoking, Eating, Makeup Application)',
            'Capability to be Integrated into Existing Fleet Montoring Systems',
        ],
        applications: [
            'Monitoring of Truck and Car Drivers',
            'Monitoring of Construction Machine Operators',
            'Monitoring of Pilots',
            'Monitoring of Simulator Occupants & Simulation Participants',
            'Monitoring and Supervision of Learner Drivers',
            'Monitoring of Security Operators (e.g. viewing of video walls)',
            'Monitoring and Analyses of Shopper Behavior',
            'Monitoring of Mine, Plant or Factory Workers',
            'Checking whether Workers are Wearing Their Personal Protective Equipment (PPE) (e.g. hard hats & safety glasses)',
            'Monitoring of Occupants of Self-Driving Cars and Aircraft',
            'Monitoring of Occupants of Taxis or Ride-Sharing Vehicles',
            'Management of Fleet Driver Proficiency',
        ],
        system: 'The 5DT DriveVUE™ system consists of a Portable Device with a Camera looking at the operator/s. It uses Machine Learning and AI to detect specific events like texting, phone use and drowsiness and can alert the operator/s directly or send the information via an encrypted interface to a Server. Control room operators, supervisors and management can log onto the system via a Web App, to see what events have been detected, send a message to the operator/s, or get an instantaneous view of the operator/s in the case of an emergency.',
        datasheet: ['A data sheet of this product may be downloaded by clicking '],
        email: {
            subject: EMAIL_SUBJECT2,
            body: EMAIL_BODY.join('\n'),
        },
    },
    product2: {
        title: 'FeverVUE™',
        sub: 'Screening Application and Temperature Measurement System',
        patent: '',
        about: PRODUCT2_OVERVIEW,
        sections: {
            overview: 'Product Overview',
            features: 'Features',
            applications: 'Application Areas',
            system: 'System Description',
            datasheet: 'Data Sheet',
        },
        features: [
            'Symptom screening from home',
            'Editable screening questions',
            'Colour coded screening results',
            'Contact tracing function',
            'Contactless temperature measurement',
            'AI face mask detection',
            'Contactless user identification via QR codes',
            'Facial indexing for access control',
            'Liveness detection',
            'API for integration into existing systems',
            'Searchable database of registered users',
            'Viewable screening results and screening history',
            'Admin dashboard with system statistics',
            'Editable terms and conditions',
            'Exportable statistics',
            'Visual representations of statistics',
            'Multiple user types (employees, visitors, security officers, managers, administrators)',
        ],
        applications: [
            'Controlling and restricting workplace access',
            'Screening employees and visitors',
            'Tracing employee contact',
            'Monitoring employee symptoms',
            'Identifying at-risk employees',
            'Enforcing employee isolation',
            'Enforcing face mask wearing',
            'Viewing screening statistics',
        ],
        system: 'The 5DT FeverVUE system consists of a screening application and a temperature measurement station. The application and station can be used together or as stand-alone systems.',
        subsystem1: 'The Screening Application can be accessed from any device (PC or mobile). It presents a set of screening questions to the user. This allows the user to log potential COVID-19 symptoms or pre-existing medical conditions. The system then presents a colour coded screening result to the user. An amber result indicates that the user may continue to the workplace for temperature scanning; a red result indicates that the user may not enter the workplace. A QR code is generated if the user receives an amber screening result',
        subsystem2: 'The Temperature Measurement Station consists of a display stand, monitor, processing device, thermal camera and normal visual camera. Artificial intelligence software is used for QR code scanning, facial indexing and liveness detection. This software is also used to measure the user’s temperature and to determine whether the user is wearing a face mask. A red screening result is given if a mask is not worn. A colour coded screening result is then presented to the user. A green result indicates that the user may enter the workplace; a red result indicates that the user may not enter the workplace.',
        datasheet: ['A data sheet of this product may be downloaded by clicking '],
        email: {
            subject: EMAIL_SUBJECT3,
            body: EMAIL_BODY.join('\n'),
        },
    },
    product3: {
        title: 'SimVUE™',
        sub: 'Simulator Occupant Tracking & Evaluation System',
        patent: '',
        about: PRODUCT3_OVERVIEW,
        sections: {
            overview: 'Product Overview',
            features: 'Features',
            applications: 'Application Areas',
            system: 'System Description',
            datasheet: 'Data Sheet',
        },
        features: [
            'Facial Recognition',
            'Dwell time measurement',
            'Drowsiness detection',
            'Eye tracking',
            'Eyes-off-road detection',
            'Mirror and instrument checking detection',
            'Body part detection',
            'Hands on/off steering wheel detection',
            'Head position tracking',
            'Head orientation tracking',
            'Body pose calculation',
            'Gesture recognition',
            'Personal protective equipment (PPE) checking',
            'Mobile phone usage detection',
            'Seatbelt checking',
            'Single or multiple camera support',
            'Gaze point heatmap',
            'Application programming interface (API)',
            'Frictionless non-invasive operation',
        ],
        applications: [
            'Monitoring of training simulator occupants',
            'Monitoring of training device users',
            'Monitoring of part task trainer users',
            'Monitoring of simulation participants',
        ],
        datasheet: ['A data sheet of this product may be downloaded by clicking '],
        email: {
            subject: EMAIL_SUBJECT4,
            body: EMAIL_BODY.join('\n'),
        },
    },
    product4: {
        title: 'SecuriVUE™',
        sub: 'AI Enhanced Video Management System',
        patent: '',
        about: PRODUCT4_OVERVIEW,
        sections: {
            overview: 'Product Overview',
            features: 'Features',
            applications: 'Application Areas',
            system: 'System Description',
            datasheet: 'Data Sheet',
        },
        features: [
            'Firearm Detection',
            'Facial recognition',
            'License plate detection',
            'Unattended object detection',
            'Loitering detection',
            'Person and object tracking',
            'Person and object counting',
            'Access control',
            'Geolocation of people and objects',
            'Geofencing',
            'Vehicle model recognition',
            'Threat detection',
            'Person to person linking',
            'Person to location linking',
            'Demographics detection (age and gender)',
            'Real-time or off-line processing',
            'Multi camera support',
            'Multi network camera standard support',
            'Modular AI processor design',
            'Person image masking',
            'Plugins for other video management systems',
        ],
        applications: [
            'Airports',
            'Smart Cities',
            'Neigborhoods',
            'Military bases',
            'Shopping malls',
            'Depots',
            'Stations',
            'Schools',
            'Hotels',
            'Buildings',
            'Home Security',
        ],
        datasheet: ['A data sheet of this product may be downloaded by clicking '],
        email: {
            subject: EMAIL_SUBJECT5,
            body: EMAIL_BODY.join('\n'),
        },
    },
    product5: {
        title: 'ScreenVUE™',
        sub: 'Video Wall & Baggage Scanner Operator Evaluation System',
        patent: '',
        about: PRODUCT5_OVERVIEW,
        sections: {
            overview: 'Product Overview',
            features: 'Features',
            applications: 'Application Areas',
            system: 'System Description',
            datasheet: 'Data Sheet',
        },
        features: [
            'Looking Point Measurement',
            'Dwell time measurement',
            'Operator drowsiness detection',
            'Eyes-off-screen detection',
            'Operator position detection',
            'Looking point superimposition on video wall',
            'Dwell time heatmap',
            'Multiple viewer support',
        ],
        applications: [
            'Monitoring of video wall operators',
            'Monitoring of baggage scanner operators',
            'Monitoring of control room operators',
            'Monitoring of shopfront display supervisors',
            'Monitoring of museum display supervisors',
        ],
        datasheet: ['A data sheet of this product may be downloaded by clicking '],
        email: {
            subject: EMAIL_SUBJECT6,
            body: EMAIL_BODY.join('\n'),
        },
    },
    contact: {
        title: 'Contact Us',
        sub: 'Please contact us for further information and to book a demonstration.',
        href: '/#contact_us',
        maps: {
            address: 'Address',
        },
        americas: {
            title: 'Americas',
            call: '+1 407 734 5377',
            callHref: '+1-407-734-5377',
            placeId: 'ChIJ74GfdVTd3IAReDnGs6t_UMQ',
            address: ['12249 Science Drive', 'Suite 135', 'Orlando', 'FL 32826', 'USA'].join(', '),
        },
        world: {
            title: 'Rest of World',
            call: '+27 12 349 2690',
            callHref: '+27-12-349-2690',
            placeId: 'ChIJXYsMqUNglR4RjzKxnfc8_Ns',
            address: ['25A De Havilland Crescent', 'P.O. Box 5', 'Persequor Park', 'Pretoria', '0020', 'South Africa'].join(', '),
        },
        sections: {
            email: {
                heading: 'Email',
            },
            links: {
                heading: 'Links',
            },
        },
        form: {
            firstName: {
                title: 'First Name',
            },
            lastName: {
                title: 'Last Name',
            },
            email: {
                title: 'Email',
            },
            company: {
                title: 'Company',
            },
            country: {
                title: 'Country',
            },
            tel: {
                title: 'Phone Number',
            },
            message: {
                title: 'Information Required / Comments',
            },
            subscribe: {
                title: 'Subscribe to 5DT Mailing List',
            },
            send: {
                title: 'Send Message',
            },
            clear: {
                title: 'Clear',
            },
            success: {
                content: 'Thank you for contacting us. We have received your message and will respond to it as soon as possible.',
            },
            error: {
                content: 'Sorry, something went wrong. Please try again.',
            },
            retry: {
                content: 'New Message'
            },
        },
    },
    email: {
        address: 'sales.ai@5dt.com',
        wwwSim: 'www.5dt.com',
        wwwAi: 'www.5dt.ai',
        requestInfo: 'Request Info',
        contactMe: 'Contact Me',
        subject: EMAIL_SUBJECT,
        body: EMAIL_BODY.join('\n'),
    },
    actions: {
        datasheet: 'View Data Sheet',
        getStarted: 'Get Started',
        learnMore: 'Learn More',
        callUs: 'Call Us',
        requestCall: 'Request Call',
        emailUs: 'Email Us',
        requestInfo: 'Request Info',
        contactUs: 'Contact Us',
    },
    links: {
        product1: {
            href: 'https://www.youtube.com/watch?v=NFMQL8eGLvU'
        },
        product2: {
            href: 'https://www.youtube.com/watch?v=zbN4e8sjcIM'
        },
        youtube: {
            href: 'https://www.youtube.com/watch?v=YOAzHjX-gaU',
            title: 'YouTube',
        },
        main: {
            href: 'http://www.5dt.com',
            title: 'Simulator Division',
        },
        contact: {
            href: '/contact',
            title: 'Contact Form',
        },
    },
    footer: {
        copyright: 'Fifth Dimension Technologies. All Rights Reserved.',
        attribution: {
            design: {
                title: 'Design',
                name: 'HTML5 UP',
                link: 'https://html5up.net',
            },
            logos: {
                title: 'Logos',
                name: 'gilbarbara',
                link: 'https://github.com/gilbarbara/logos',
            },
        },
    },
    header: {
        logo: '5DT.AI',
        name: 'Machine Learning Division',
        menu: 'Menu',
    },
    images: {
        system: {
            caption: 'System Overview',
        },
        app: {
            caption: 'Typical Web App view of the system',
        },
        map: {
            caption: 'Typical Map View as on the Web App, showing typical Icons',
        },
    },
    images2: {
        system: {
            caption: 'System Overview',
        },
        app1: {
            caption: 'Typical employee information view of the system',
        },
        app2: {
            caption: 'Typical statistics view of the system',
        },
    },
    images3: {
        system: {
            caption: 'SimVUE™ with heatmap functionality turned on. The heatmap shows the dwell time of the simulator occupant’s gaze point. The simulator occupant is checking his left mirror. Both the operator’s hands are on the steering wheel.',
        },
    },
    images4: {
        system: {
            caption: 'System usage examples',
        },
    },
    images5: {
        overview1: {
            caption: 'The ‘looking point’ of the viewer/operator is shown by means of a color-coded dot.',
        },
        overview2: {
            caption: 'The duration of how long the viewer/operator looked at a specific point is represented by a heat map.',
        },
        scanner1: {
            caption: 'Another example is where the ‘looking point’ of a baggage scanner operator is superimposed on a baggage scanner image.',
        },
        scanner2: {
            caption: 'The dwell time of the viewer/operator is represented by means of a heat map.',
        },
    },
    error: {
        title: 'Error Page',
        message: "Hmmm, looks like we don't have that. Please try something else."
    },
}
