// https://github.com/rcaferati/react-awesome-button#examples
import React from 'react'
import { navigate } from 'gatsby'

import { compose, pure } from 'recompose'

import CONTENT from 'content/content'

export default compose(
    pure,
)(props => {
    return (
        <div 
            role="link" 
            onClick={() => navigate(CONTENT.links.contact.href)} 
            className="button special">
            <span className="fa fa-fw fa-comments-o"></span> {CONTENT.links.contact.title}
        </div>
    )
})
